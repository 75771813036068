import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    planPanelExpand: false,
    changePackageTo: null,
}

export const selectPlanSlice = createSlice({
    name: 'checkout/selectPlan',
    initialState,
    reducers: {
        setPlanPanelExpand: (state, action) => {
            state.planPanelExpand = action.payload
        },

        setChangePackageTo: (state, action) => {
            state.changePackageTo = action.payload
        },
    },
})

export const { setPlanPanelExpand, setChangePackageTo } =
    selectPlanSlice.actions

export default selectPlanSlice.reducer
