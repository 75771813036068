import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTitles: [],
    premiumTitlesCount: 0,
    standardTitlesCount: 0,
}

const titlesSlice = createSlice({
    name: 'checkout/titles',
    initialState,
    reducers: {
        clearCheckout: (_, action) => initialState,
        addTitle: (state, action) => {
            const existingTitle = state.selectedTitles.find(
                (title) => title.id === action.payload.id
            )
            if (
                !existingTitle &&
                action.payload.titleClassification?.titleClassification
            ) {
                state.selectedTitles.push(action.payload)
                if (
                    action.payload.titleClassification.titleClassification ===
                    'Premium'
                ) {
                    state.premiumTitlesCount += 1
                } else if (
                    action.payload.titleClassification.titleClassification ===
                    'Standard'
                ) {
                    state.standardTitlesCount += 1
                }
            } else {
                console.log(action.payload)
            }
        },
        removeTitle: (state, action) => {
            const indexToRemove = state.selectedTitles.findIndex(
                (title) => title.id === action.payload
            )
            if (indexToRemove !== -1) {
                const removedTitle = state.selectedTitles[indexToRemove]
                state.selectedTitles.splice(indexToRemove, 1)
                if (
                    removedTitle.titleClassification.titleClassification ===
                    'Premium'
                ) {
                    state.premiumTitlesCount -= 1
                } else if (
                    removedTitle.titleClassification.titleClassification ===
                    'Standard'
                ) {
                    state.standardTitlesCount -= 1
                }
            }
        },
    },
})

export const { addTitle, removeTitle, clearCheckout } = titlesSlice.actions
export default titlesSlice.reducer
