import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filtersList: [],
    filterResultPageIndex: null,
}

const filtersSlice = createSlice({
    name: 'search/filters',
    initialState,
    reducers: {
        setFiltersList: (state, action) => {
            state.filtersList = action.payload
        },
        setFilterResultPageIndex: (state, action) => {
            state.filterResultPageIndex = action.payload
        },
        clearFiltersList: (_, action) => initialState,
    },
})

export const { setFiltersList, clearFiltersList, setFilterResultPageIndex } =
    filtersSlice.actions

export default filtersSlice.reducer
